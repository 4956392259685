/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
    box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
    margin: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}
/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
    font: inherit;
}
/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}
/*
  8. Create a root stacking context
*/
#root,
#__next {
    isolation: isolate;
}

button,
button:focus,
button:active {
    background-color: transparent;
    color: inherit;
    outline: none;
    border: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a,
a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

.map-popup {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    -webkit-box-shadow: 8px 8px 35px -18px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 8px 8px 35px -18px rgba(0, 0, 0, 0.7);
    box-shadow: 8px 8px 35px -18px rgba(0, 0, 0, 0.7);
    display: flex;
    gap: 20px;
    z-index: 10000000000000;
    width: 100%;
    line-height: 1.5;
}

@media screen and (max-width: 768px) {
    .map-popup {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.map-popup__image {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 6px;
}

.map-popup__name {
    font-weight: bold;
}
